.btn_container {
	width: 191px;
	border-radius: 2px;
	background: var(--white-color);
	margin-top: 10px;
	border: 1px solid var(--neutral-n-30);
}

.btn_container p {
	padding: 10px 16px;
}
