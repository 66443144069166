.receipt-header {
    border-bottom: 1px solid var(--neutral-n-30);
    border-top: 1px solid var(--neutral-n-30);
    padding: 1rem 0;
    text-align: center;
}

.receipt-header img {
    width: 140px;
    height: 140px;
}

.receipt-header h4 {
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 28px;
    margin-bottom: 10px;
}

.receipt-header p {
    font-weight: lighter;
    font-size: 1.2rem;
}

.receipt-header h6 {
    text-transform: none;
    font-style: normal;
    font-weight: normal;
    font-size: 1.14rem;
    line-height: 24px;
    margin-bottom: 8px;
}

.i-address h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 28px;
    margin-bottom: 16px;
}

.receipt-address h6 {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 20px;
    text-transform: none;
    margin-bottom: 8px;
}

.receipt-address h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 24px;
    text-transform: none;
    margin-bottom: 8px;
}

.receipt-in-words h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 20px;
    text-align: left;
    text-transform: none;
    margin-bottom: 8px;
}
.receipt-in-words-value {
    border-bottom: 1px  solid var(--neutral-n-30);
}

.receipt-table {
    margin-top: 77px;
}

.receipt-table-header {
    background: var(--neutral-n-30);
}

.receipt-table-header h5 {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 28px;
}

.receipt-table-children {
    margin-bottom: 40px;
}

.receipt-table-children p {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 1.14rem;
    line-height: 24px;
}

.receipt-table-total {
    background: var(--neutral-n-30);
    margin-bottom: 64px;
}

.receipt-table-total p {
    margin: 0;
    font-style: bold;
    font-weight: normal;
    font-size: 1.14rem;
    line-height: 24px;
}

.receipt-important {
    text-align: center;
}

.receipt-powered {
    margin-top: 100px;
}

.receipt-powered p {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 20px;
    flex-wrap: wrap;
}

.receipt-link {
    background: var(--secondary);
    color: var(--dark-bg-text-color);
    text-decoration: none;
}

.receipt-link:hover {
    color: var(--dark-bg-text-color);
    background: #36b37e;
    text-decoration: none;
}