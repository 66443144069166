.dashboard_card {
	padding: 20px;
	border-radius: 8px;
	border: 1px solid var(--neutral-border);
	background-color: var(--white-color);
	height: 100%;
}

.dahboard_card_title {
	color: var(--darker-gray);
	margin: 0;
	padding: 0;
	margin-top: 12px;
	text-align: start;
	margin-bottom: 20px;
}

.dashboard_card_value {
	color: var(--text-color);
}
