.container {
	scroll-behavior: smooth;
}

.container label {
	font-weight: 500;
}

.profile_img span {
	font-size: 2.86rem;
}

.profile_img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.upload_button {
	/* margin-left: 20px; */
	margin-bottom: 38px;
}

.sub_header {
	font-size: 1.14rem;
	line-height: 20px;
}

.key_comes_tabs {
	position: sticky;
	top: 180px;
}

.key_comes_sticky {
	position: absolute;
	transform: translateX(-50%);
	left: 75px;
	width: 100%;
}

.signature_text_style {
	color: var(--primary);
	cursor: pointer;
}

.ailment_container {
	border-radius: 4px;
	padding: 9.5px 12px;
}

.input {
	display: none;
}

.welcome_text {
	font-style: normal;
	font-weight: 450;
	font-size: 1rem;
	line-height: 20px;
	color: var(--alt-text-color-3);
}

.note_text {
	color: var(--alt-text-color-3);
	font-size: 1rem;
}

.cancel {
	background: var(--neutral-20);
}

.preview_container p,
.preview_container h4,
.preview_container h5,
.preview_container h6 {
	text-align: left !important;
}

@media (max-width: 1200px) {
	.key_comes_sticky {
		position: relative;
		top: 0;
		left: 0;
		transform: none;
	}
}
