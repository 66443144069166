.accordion_container {
	width: 100%;
	border-bottom: 0.4px solid var(--neutal-60);
	cursor: pointer;
}

.accordion_title {
	font-size: 1.14rem;
	font-weight: 600;
}

.accordion_down_button {
	background-color: transparent;
	border: 0px;
	cursor: pointer;
}

.accordion_body {
	width: 100%;
	height: 0px;
	overflow: hidden;
	margin-bottom: 30px;
}

.accordion_body_expand {
	width: 100%;
	height: fit-content;
	margin-bottom: 30px;
}

.normal_accordion_img {
	transform: rotate(0deg);
	transform-origin: center;
	transition: 0.2s ease-in-out;
}

.rotate_accordian_img {
	transform: rotate(180deg);
	transform-origin: center;
	transition: 0.2s ease-in-out;
}
