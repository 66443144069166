.invoice-header {
	border-bottom: 1px solid var(--secondary);
}

.invoice-header img {
	width: 140px;
	height: 140px;
}

.invoice-header h4 {
	font-weight: normal;
	font-size: 1.71rem;
	line-height: 28px;
	margin-bottom: 8px;
}

.invoice-header h6 {
	text-transform: none;
	font-style: normal;
	font-weight: normal;
	font-size: 1.14rem;
	line-height: 24px;
	margin-bottom: 8px;
}

.invoice-address h4 {
	font-style: normal;
	font-weight: normal;
	font-size: 1.36rem;
	line-height: 28px;
	margin-bottom: 16px;
}

.invoice-address h6 {
	font-style: normal;
	font-weight: normal;
	font-size: 1rem;
	line-height: 20px;
	text-transform: none;
	margin-bottom: 8px;
}

.invoice-address h5 {
	font-style: normal;
	font-weight: bold;
	font-size: 1.14rem;
	line-height: 24px;
	text-transform: none;
	margin-bottom: 8px;
}

.invoice-table {
	margin-top: 77px;
}
.invoice-table-header {
	background: var(--light-green);
}

.invoice-table-header h5 {
	margin: 0;
	font-style: normal;
	font-weight: normal;
	font-size: 1.36rem;
	line-height: 28px;
}

.invoice-table-children {
	margin-bottom: 40px;
}

.invoice-table-children p {
	margin: 0;
	font-style: normal;
	font-weight: normal;
	font-size: 1.14rem;
	line-height: 24px;
}

.invoice-table-total {
	background: var(--lighter-green);
	margin-bottom: 64px;
}

.invoice-table-total p {
	margin: 0;
	font-style: bold;
	font-weight: normal;
	font-size: 1.14rem;
	line-height: 24px;
}
.invoice-important p {
	font-style: normal;
	font-weight: normal;
	font-size: 1rem;
	line-height: 20px;
	color: var(--danger);
}
.invoice-powered {
	margin-top: 100px;
}
.invoice-powered p {
	font-style: normal;
	font-weight: normal;
	font-size: 1rem;
	line-height: 20px;
	flex-wrap: wrap;
}

.invoice-link {
	background: var(--secondary);
	color: var(--dark-bg-text-color);
	text-decoration: none;
}

.invoice-link:hover {
	color: var(--dark-bg-text-color);
	background: #36b37e;
	text-decoration: none;
}