.application_tab_tabs {
	width: 301px;
	height: fit-content;
	overflow: hidden;
	border-radius: 6px 0px 0px 6px;
	background-color: var(--white-color);
	border: 0.2px solid var(--neutal-60);
	border-right: 0;
}

.application_tab {
	width: 100%;
	height: 88px;
	position: relative;
	background-color: var(--neutral-n-30);
	display: flex;
	align-items: center;
	cursor: pointer;
	transition: 0.2s ease-in-out;
	padding: 0px 30px;
}

.active_tab_applications {
	background-color: var(--white-color);
}

.application_tab:hover {
	background-color: var(--white-color);
}

.application_tab_text {
	font-size: 1.36rem;
	color: var(--alt-text-color-tint);
}

.application_tab_indicator {
	display: none;
	width: 8px;
	height: 100%;
	position: absolute;
	background-color: var(--primary);
	top: 0;
	left: 0;
}

.application_tab_indicator_show {
	display: block;
}

.application_body {
	flex: 1;
	max-width: 900px;
	border: 0.2px solid var(--neutal-60);
	min-height: 532px;
	padding: 32px;
	border-radius: 0px 6px 6px 6px;
}

@media (max-width: 767px) {
	.application_tab_container {
		flex-direction: column;
	}

	.application_tab_tabs {
		width: 100%;
		border-right: 0.2px solid var(--neutal-60);
	}
}
