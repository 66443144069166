.container {
	background-position: center;
	background-attachment: fixed;
	width: 100%;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.sectionsContainer {
	max-width: 1240px;
	margin: 0 auto;
}
.firstCover .sectionsContainer section > div:first-of-type {
	padding: 86px 20px;
}

.landing_sect {
	position: relative;
	padding: 0;
	display: flex;
	justify-content: flex-end;
	max-height: 556px;
}

.landing_sect > img {
	width: 100%;
	object-fit: cover;
}
.firstCover {
	position: relative;
	background-size: contain;
	background-position: center;
	isolation: isolate;
	overflow: hidden;
}
.firstCover::after {
	content: "";
	position: absolute;
	bottom: -10px;
	left: clamp(-60px, 15vw, -80px);
	width: clamp(150px, 20vw, 200px);
	aspect-ratio: 1/1;
	transform: rotate(45deg);
	z-index: -1;
	background: #eff5f9;
}

.notice {
	display: inline-block;
	background-color: var(--transparent-white);
	color: var(--white-color);
	padding: 5px 15px 5px 12px;
	border-radius: 100px;
	animation: tilt-shaking 0.35s infinite;
	animation-iteration-count: 7;
	font-size: 0.75rem;
}

.notice span {
	display: inline-block;
	border-radius: 100px;
	background-color: var(--light-red);
	padding: 5px 10px;
	margin-right: 10px;
	font-size: 0.75rem;
}

.cardsContainer {
	width: 90%;
	margin: -50px auto;
	margin-bottom: 50px;
	padding: 20px;
	background-color: var(--disabled);
	border-radius: 12px;
	z-index: 999;
}
.btn {
	border-radius: 4px;
	background: var(--primary);
	padding: 14px 16px;
	font-size: 20px;
	font-style: normal;
	font-weight: 450;
	line-height: 15.96px;
	color: var(--dark-bg-text-color);
	border: none;
	margin-top: 25px;
}
.card {
	display: inline-block;
	background: var(--white-color);
	border-radius: 6px;
	padding: 40px;
	width: 100%;
}

.card > * {
	margin-bottom: 15px;
}

.card p {
	color: var(--text-neutral);
	text-align: left !important;
}

.cardLinks {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 32px;
}

.cardLinks a {
	text-decoration: none;
	color: var(--primary);
	font-weight: 500;
}

.cardLinks a:hover {
	text-decoration: underline;
	color: var(--primary);
}

.welcome_circle {
	position: absolute;
	bottom: 0;
	animation-name: spin;
	animation-duration: 10000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	transform-origin: center;
}

.sectionsContainer section h1 {
	font-size: clamp(2rem, 3.5vw, 4.41rem);
	line-height: 40px;
	color: var(--text-color);
	max-width: 587px;
	position: relative;
}

.sectionsContainer section h1::after {
	content: "";
	position: absolute;
	top: -20px;
	right: 20%;
	width: clamp(70px, 10vw, 140px);
	aspect-ratio: 1/1;
	transform: rotate(45deg);
	z-index: -1;
	background: #eff5f9;
}
.sectionsContainer section p {
	font-size: 1.14rem;
	line-height: 25.888px;
	margin-top: 16px;
	color: var(--text-color);
	text-align: left !important;
	max-width: 557px;
}

.application_section_container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 50px 0px;
	background-color: var(--neutral-n-30);
	border-radius: clamp(1rem, 4vw, 2rem);
	max-width: 1240px;
	margin: 0 auto;
	margin-top: 0.5rem;
}
.application_section_container_cards {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	padding: clamp(1rem, 4vw, 4rem) clamp(1rem, 4vw, 3rem);
	gap: 2rem;
}

.application_section_container_cards > .application_card {
	background-color: var(--dark-bg-text-color);
	width: 100%;
	height: 280px;
	border-radius: clamp(0.5rem, 4vw, 1rem);
	padding: 40px;
}
.application_section_container_cards > .application_card > h3 {
	color: var(--text-color);
	font-size: 1.1875rem;
	font-weight: 700;
	text-transform: capitalize;
}

.application_section_header h2,
.application_section_header p {
	text-align: center;
}

.home_footer {
	box-shadow: 0px 2px 6px 0px var(--box_shadow);
	padding: 16px;
	bottom: 0;
	position: relative;
	width: 100%;
}
.fab {
	background-color: var(--primary);
	width: 2.7rem;
	height: 2.7rem;
	border-radius: 4px;
	position: absolute;
	right: 20px;
	top: -50px;
	transition: all 0.3s ease;
}
.fab:hover {
	bottom: 5%;
	cursor: pointer;
}
.footer_outer_container {
	margin-top: 100px;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes tilt-shaking {
	0% {
		transform: translate(0, 0) rotate(0deg);
	}
	25% {
		transform: translate(5px, 5px) rotate(5deg);
	}
	50% {
		transform: translate(0, 0) rotate(0eg);
	}
	75% {
		transform: translate(-5px, 5px) rotate(-5deg);
	}
	100% {
		transform: translate(0, 0) rotate(0deg);
	}
}

.sectionsContainer section {
	flex-direction: column;
	justify-content: center;
}

.sectionsContainer section svg {
	display: none;
}
.desktop_landing_img {
	display: none;
}
@media (min-width: 768px) {
	.notice,
	.notice span {
		font-size: 1rem;
	}

	.sectionsContainer section {
		flex-direction: row;
		justify-content: initial;
	}
	.sectionsContainer section svg {
		display: block;
	}

	.cardsContainer {
		padding: 40px;
		margin: -50px auto;
		margin-bottom: 50px;
		width: 90%;
	}
	.card {
		padding: 50px;
	}

	.sectionsContainer section h1 {
		color: var(--text-color);
		width: 100%;
	}

	.sectionsContainer section p {
		font-size: 1.14rem;
		line-height: 25.888px;
		margin-top: 16px;
		color: var(--text-color);
	}
}
@media (min-width: 800px) {
	.application_section_container_cards {
		flex-wrap: nowrap;
	}
}
@media (min-width: 880px) {
	.landing_sect > img {
		display: none !important;
	}
	.landing_sect {
		display: none;
	}
}

@media (min-width: 1100px) {
	.sectionsContainer {
		height: 100vh;
		max-height: 681px;
		padding: 20px;
		padding-top: 50px;
		position: relative;
	}

	.sectionsContainer section h1 {
		width: 100%;
		line-height: 66px;
	}

	.landing_sect {
		display: block;
		position: absolute;
		top: 0;
		right: -60px;
		bottom: 0;
		margin: 0;
		height: 100%;
		max-height: unset;
		width: 100%;
		max-width: 686px;
	}
	.desktop_landing_img {
		display: block;
		height: 100%;
		width: 100%;
	}

	.desktop_landing_img > img {
		height: 100%;
		width: 100%;
		margin-top: 5%;
		object-fit: contain;
		transition: all ease-in-out 0.3s;
		scale: 0.9;
	}

	.welcome_circle {
		position: absolute;
		bottom: 0;
		animation-name: spin;
		animation-duration: 10000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		transform-origin: center;
	}

	.cardsContainer {
		padding: 80px;
		margin: -100px auto;
		margin-bottom: 100px;
	}
}
@media (min-width: 1100px) and (max-width: 1181px) {
	.sectionsContainer section {
		width: 40% !important;
	}
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes bounce {
	0% {
		transform: translateY(-30px);
	}

	50% {
		transform: translateY(0px);
	}

	100% {
		transform: translateY(-30px);
	}
}
