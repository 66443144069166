.footer_container {
    width: 100%;
    height: 72px;
    background-color: var(--white-color);
    padding: 0px 20px
}

.footer_container p {
    font-size: 1rem;
    color: var(--light-text-color);
}