.landing_card_container {
	min-width: 250px;
	background-color: var(--neutral-n-30);
	padding: 1.5rem 2.5rem;
	transition: all 0.1s ease;
	position: relative;
	display: block !important;
	text-decoration: none;
}
.landing_card_container h4 {
	font-weight: 700;
}
.marker {
	width: 5px;
	height: 80px;
	position: absolute;
	background-color: var(--primary);
	top: 0;
	left: 0;
	opacity: 0;
	border-top-left-radius: 30px;
}
.landing_card_container::before {
	content: "";
	width: 5px;
	height: 100%;
	position: absolute;
	background-color: var(--primary);
	top: 0;
	left: 0;
	opacity: 0;
	border-top-left-radius: 30px;
	transition: all 0.2s ease;
}

.landing_card_container:hover {
	cursor: pointer;
	text-decoration: none;
}
.landing_card_container:hover::before {
	opacity: 1;
}

.active-card {
	width: 5px;
	height: 100%;
	position: absolute;
	background-color: var(--primary);
	top: 0;
	left: 0;
	display: inline;
	border-top-left-radius: 30px;
}
.backColor {
	background-color: var(--primary);
	width: 3px;
	height: 80px;
	border-top-left-radius: 30px;
}
.inactive {
	width: 5px;
	height: 80px;
	position: absolute;
	background-color: red;
	top: 0;
	left: 0;
	opacity: 0;
	border-top-left-radius: 30px;
}
.active-card-background {
	background-color: var(--white-color);
	transition: all 0.1s ease;
}

@media (max-width: 768px) {
	.landing_card_container {
		width: initial !important;
		padding: 1rem 1.4rem;
		background-color: #e3e4e7;
	}

	.active-card-background {
		background-color: #e8e9ea;
	}
}
