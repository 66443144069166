.infoDetails {
	/* width: 65.4rem; */
	width: 55vw !important;
	/* height: 39.5rem; */
	height: 100% !important;
	background-color: var(--white-color);
	padding: 64px 56px;
}
.infoDetails h6 {
	font-size: 1.2rem;
	line-height: 1.5rem;
	margin-bottom: 1rem;
}
.infoDetails h5 {
	font-style: normal;
	font-weight: 450;
}
.infoDetails p,
li {
	line-height: 1.5rem;
}
.infoDetails a {
	text-decoration: none;
	display: block;
	line-height: 12px;
	color: var(--text-green);
	font-weight: 500;
	margin-top: 12px;
	width: fit-content;
}
.infoDetails a:hover {
	color: var(--light-green-color);
}

@media (max-width: 768px) {
	.infoDetails {
		width: 100% !important;
		height: 100%;
		background-color: var(--white-color);
		padding: 40px 0px;
	}
	.infoDetails p,
	ul {
		text-align: justify;
		text-justify: inter-word;
		/* padding: 64px 56px; */
	}
	.infoDetails h5 {
		font-size: 1rem;
	}
}
