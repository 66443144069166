.page_title_custom {
	flex-wrap: wrap;
	padding: 1rem;
}

.page_title_custom h4 {
	font-style: normal;
	font-weight: normal;
	font-size: 1.71rem;
	line-height: 28px;
	margin: 0;
}
