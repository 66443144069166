.container {
	position: relative;
	background: var(--white-color);
}

.watermark {
	top: 50%;
	left: 50%;
	transform: translate(-50%);
	position: absolute;
	opacity: 0.1;
	z-index: 0;
}

.avatar span {
	font-size: 100px;
}

.logo_header {
	background-color: var(--lighter-green);
}
